import { TableCell } from "@mui/material"
import { useEffect, useRef } from "react"
import type { FC, ReactNode } from "react"

type Props = {
  setWidth?: (width: number) => void
  children: ReactNode
}

export const NoteTableCell: FC<Props> = (props) => {
  const noteRef = useRef<HTMLTableCellElement>(null)

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.target === noteRef.current && props.setWidth) {
          props.setWidth(noteRef.current.clientWidth)
        }
      })
    })

    noteRef.current && resizeObserver.observe(noteRef.current)

    return () => resizeObserver.disconnect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <TableCell
      component="th"
      scope="row"
      sx={{
        minWidth: 120,
        maxWidth: 240,
        left: 0,
        background(theme) {
          return theme.palette.mode === "light"
            ? theme.palette.common.white
            : theme.palette.grey[900]
        },
        zIndex: 1,
        overflowX: "hidden",
        textOverflow: "ellipsis",
        fontWeight: "bold",
      }}
      ref={noteRef}
    >
      {props.children}
    </TableCell>
  )
}
