import { Stack } from "@mui/material"
import { memo, useState } from "react"
import { ShiftEmptyButton } from "app/[business]/shifts/components/ShiftEmptyButton"
import { ShiftTableCellEdit } from "app/[business]/shifts/components/ShiftTableCellEdit"
import { ShiftWarningDialog } from "app/[business]/shifts/components/ShiftWarningDialog"

type Props = {
  isReadOnly: boolean
  onAssign(): void
}

/**
 * 差分が発生することはないので再レンダリングしない
 * @returns
 */
const propsAreEqual = (prevProps: Props, nextProps: Props) => {
  return prevProps.isReadOnly === nextProps.isReadOnly
}

/**
 * テーブルの空のセル
 */
export const ShiftTableCellEmpty = memo<Props>((props) => {
  const [isOpenDialogUnavailable, openDialogUnavailable] = useState(false)

  const onOpenDialogUnavailable = () => {
    openDialogUnavailable(true)
  }

  const onCloseDialogUnavailable = () => {
    openDialogUnavailable(false)
  }

  const onUpdateUnavailable = () => {
    props.onAssign()
    openDialogUnavailable(false)
  }

  return (
    <>
      <Stack direction={"row"} position="relative">
        <ShiftEmptyButton
          isDisabled={props.isReadOnly}
          onClick={onOpenDialogUnavailable}
        />
        {!props.isReadOnly && (
          <ShiftTableCellEdit isDisabled={true} onClick={() => {}} />
        )}
      </Stack>
      <ShiftWarningDialog
        isOpen={isOpenDialogUnavailable}
        onSubmit={onUpdateUnavailable}
        onClose={onCloseDialogUnavailable}
      />
    </>
  )
}, propsAreEqual)
