import { Drawer as MuiDrawer, useTheme, useMediaQuery } from "@mui/material"
import type { FC, ReactNode } from "react"

type Props = {
  children: ReactNode
  open: boolean
  onClose(): void
}

/**
 * @description breakpointごとにDrawerの開閉方向を変える
 */
export const ResponsiveDrawer: FC<Props> = (props) => {
  const theme = useTheme()

  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"))

  return (
    <MuiDrawer
      open={props.open}
      onClose={props.onClose}
      anchor={isDesktop ? "right" : "bottom"}
    >
      {props.children}
    </MuiDrawer>
  )
}
