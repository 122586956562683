import { useState } from "react"

export const useWeekOffset = (defaultValue?: number) => {
  const [offset, setOffset] = useState(defaultValue ?? 0)

  const isNextWeek = offset === 0

  const isThisWeek = offset === 1

  const onSelectThisWeek = () => {
    setOffset(1)
  }

  const onSelectNextWeek = () => {
    setOffset(0)
  }

  const onChange = (n: number) => {
    setOffset(n)
  }

  return {
    offset,
    isNextWeek,
    isThisWeek,
    onSelectThisWeek,
    onSelectNextWeek,
    onChange,
  }
}
