import CampaignIcon from "@mui/icons-material/Campaign"
import { Box, Stack, Typography, colors } from "@mui/material"
import type { FC } from "react"
import React from "react"
import { ShiftTableTextField } from "app/[business]/shifts/components/ShiftTableTextField"

type Props = {
  isEditable: boolean
  isReadOnly: boolean
  note: string
  setNote?(value: string): void
}

export const EditableNote: FC<Props> = ({
  isEditable,
  isReadOnly,
  note,
  setNote,
}) => {
  return (
    <Box sx={{ px: 2 }}>
      {isEditable ? (
        <ShiftTableTextField
          isEditable={isEditable}
          isReadOnly={isReadOnly}
          value={note}
          onChange={setNote}
        />
      ) : (
        <Stack direction="row" spacing={1} alignItems="center">
          <CampaignIcon />
          <Typography
            sx={{
              fontSize: note ? 18 : 14,
              color: note ? "initial" : colors.grey[500],
              whiteSpace: "pre-line",
            }}
          >
            {note || "No announcement available."}
          </Typography>
        </Stack>
      )}
    </Box>
  )
}
