import { Tab, Tabs } from "@mui/material"
import type { FC } from "react"
import type { ShiftRoleFieldsFragment } from "interface/__generated__/react"

type Props = {
  shiftRoles: ShiftRoleFieldsFragment[]
  onChange(value: number): void
  value: number
}

export const ShiftTableTabs: FC<Props> = (props) => {
  return (
    <Tabs
      value={props.value}
      onChange={(_, value) => {
        props.onChange(value)
      }}
    >
      <Tab label={"All"} />
      {props.shiftRoles.map((shiftRole) => (
        <Tab key={shiftRole.id} label={shiftRole.name} />
      ))}
    </Tabs>
  )
}
