import type { Theme } from "@mui/material"
import { Typography, Stack, ButtonBase } from "@mui/material"
import { cyan, grey, orange, red } from "@mui/material/colors"
import { memo } from "react"
import { ShiftTime } from "app/[business]/shifts/components/ShiftTime"

type Props = {
  label: string | null
  startTimeHour: number
  startTimeMinute: number
  endTimeHour: number
  endTimeMinute: number
  isAssigned: boolean
  isAssignable: boolean
  isDuplicated: boolean
  isReadOnly: boolean
  isDefaultStartTime: boolean
  isDefaultEndTime: boolean
  onClick?(): void
}

/**
 * シフトのセルのボタン
 */
export const ShiftToggleButton = memo<Props>((props) => {
  const backgroundColor = () => {
    if (props.isDuplicated) {
      return red[500]
    }
    if (props.isAssigned && !props.isAssignable) {
      return orange[500]
    }
    if (props.isAssigned) {
      return cyan[500]
    }
    if (props.isAssignable) {
      return grey[50]
    }
    return grey[100]
  }

  const color = (theme: Theme) => {
    if (props.isAssigned && !props.isAssignable) {
      return theme.palette.common.white
    }
    if (props.isAssigned) {
      return theme.palette.common.white
    }
    if (props.isAssignable) {
      return "inherit"
    }
    if (props.isDuplicated) {
      return "inherit"
    }
    return "inherit"
  }

  return (
    <ButtonBase
      disabled={props.isReadOnly}
      onClick={props.isReadOnly ? undefined : props.onClick}
      sx={{
        py: 0,
        px: 0,
        width: "2.5rem",
        minWidth: "2.5rem",
        maxWidth: "2.5rem",
        height: "2.5rem",
        position: "relative",
        backgroundColor: backgroundColor,
        color: color,
        border: props.isAssignable ? "1px solid gray" : null, // 枠線を追加
        borderRadius: "4px", // 角丸の枠線を追加
      }}
    >
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent="center"
        spacing={1}
        sx={{ pl: 0.25, height: "2rem" }}
      >
        <Stack
          direction="column"
          alignItems="flex-start"
          sx={{ width: "100%" }}
        >
          <ShiftTime
            isDefault={props.isDefaultStartTime}
            hour={props.startTimeHour}
            minute={props.startTimeMinute}
          />
          <ShiftTime
            isDefault={props.isDefaultEndTime}
            hour={props.endTimeHour}
            minute={props.endTimeMinute}
          />
          <Typography sx={{ whiteSpace: "unset", fontSize: 8 }}>
            {props.label ?? ""}
          </Typography>
        </Stack>
      </Stack>
    </ButtonBase>
  )
})
