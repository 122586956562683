import { Stack } from "@mui/material"
import { useState, memo } from "react"
import { ShiftTableCellEdit } from "app/[business]/shifts/components/ShiftTableCellEdit"
import { ShiftTimeDrawer } from "app/[business]/shifts/components/ShiftTimeDrawer"
import { ShiftToggleButton } from "app/[business]/shifts/components/ShiftToggleButton"
import type { OnUpdateShift } from "interface/types/onUpdateShift"

type Props = {
  label: string | null
  dateText: string
  employeeName: string
  startTime: string
  startTimeHour: number
  startTimeMinute: number
  endTime: string
  endTimeHour: number
  endTimeMinute: number
  isEditable: boolean
  isAssigned: boolean
  isAssignable: boolean
  isRequested: boolean
  isDuplicated: boolean
  isDefaultStartTime: boolean
  isDefaultEndTime: boolean
  onUpdate: OnUpdateShift
  onAssign(): void
}

const propsAreEqual = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.label === nextProps.label &&
    prevProps.startTime === nextProps.startTime &&
    prevProps.endTime === nextProps.endTime &&
    prevProps.isRequested === nextProps.isRequested &&
    prevProps.isEditable === nextProps.isEditable &&
    prevProps.isAssigned === nextProps.isAssigned &&
    prevProps.isAssignable === nextProps.isAssignable &&
    prevProps.isDuplicated === nextProps.isDuplicated
  )
}

/**
 * テーブルの空でないセル
 * @param props
 * @returns
 */
export const ShiftTableCellSlot = memo<Props>((props) => {
  const [isOpenDialog, openDialog] = useState(false)

  const onCloseDialog: OnUpdateShift = (form) => {
    openDialog(false)
    props.onUpdate(form)
  }

  const onOpenDialog = () => {
    openDialog(true)
  }

  return (
    <>
      <Stack
        direction={"row"}
        position="relative"
        sx={{
          width: "100%",
          height: "2.5rem",
          borderRadius: 0.5,
          overflow: "hidden",
        }}
      >
        <ShiftToggleButton
          label={props.label}
          startTimeHour={props.startTimeHour}
          startTimeMinute={props.startTimeMinute}
          endTimeHour={props.endTimeHour}
          endTimeMinute={props.endTimeMinute}
          isReadOnly={!props.isEditable}
          isAssigned={props.isAssigned}
          isAssignable={props.isAssignable}
          isDuplicated={props.isDuplicated}
          isDefaultStartTime={props.isDefaultStartTime}
          isDefaultEndTime={props.isDefaultEndTime}
          onClick={props.onAssign}
        />
        {props.isEditable && (
          <ShiftTableCellEdit
            isDisabled={!props.isAssigned}
            onClick={onOpenDialog}
          />
        )}
      </Stack>
      <ShiftTimeDrawer
        isOpenDrawer={isOpenDialog}
        label={props.label}
        employeeName={props.employeeName}
        dateText={props.dateText}
        startTime={props.startTime}
        endTime={props.endTime}
        onClose={onCloseDialog}
      />
    </>
  )
}, propsAreEqual)
