import { FormControl, MenuItem, Select } from "@mui/material"
import { memo } from "react"

type Props = {
  texts: string[]
  offset: number
  onChange(index: number): void
}

export const WeekOffsetPicker = memo<Props>((props) => {
  return (
    <FormControl variant="outlined" sx={{ width: "100%" }}>
      <Select
        size={"small"}
        value={props.offset}
        sx={{
          w: "100%",
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          background(theme) {
            return theme.palette.mode === "light"
              ? theme.palette.common.white
              : theme.palette.grey[900]
          },
        }}
        onChange={(event) => {
          if (typeof event.target.value !== "number") return
          props.onChange(event.target.value)
        }}
      >
        {props.texts.map((text, index) => (
          <MenuItem key={index} value={index}>
            {text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
})
