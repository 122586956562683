import { TableCell } from "@mui/material"
import type { FC, ReactNode } from "react"

type Props = {
  width: number
  children: ReactNode
}

export const VariableWidthTableCell: FC<Props> = (props) => {
  return (
    <TableCell
      component="th"
      scope="row"
      sx={{
        width: props.width,
        left: 0,
        background(theme) {
          return theme.palette.mode === "light"
            ? theme.palette.common.white
            : theme.palette.grey[900]
        },
        zIndex: 1,
        overflowX: "hidden",
        textOverflow: "ellipsis",
        fontWeight: "bold",
      }}
    >
      {props.children}
    </TableCell>
  )
}
