import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material"
import type { FC } from "react"

type Props = {
  isOpen: boolean
  onSubmit(): void
  onClose(): void
}

/**
 * ダイアログ
 * @param props
 * @returns
 */
export const ShiftWarningDialog: FC<Props> = (props) => {
  return (
    <Dialog onClose={props.onClose} open={props.isOpen}>
      <DialogTitle>{"Unavailable time range"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {"The time range you clicked is set as unavailable. Continue?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>{"Cancel"}</Button>
        <Button onClick={props.onSubmit} autoFocus>
          {"Continue"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
