import { TextField } from "@mui/material"
import type { FC } from "react"

type Props = {
  isEditable: boolean
  isReadOnly: boolean
  value: string
  onChange?(value: string): void
}

export const ShiftTableTextField: FC<Props> = (props) => {
  return (
    <TextField
      InputProps={{ readOnly: !props.isEditable || props.isReadOnly }}
      multiline={true}
      value={props.value}
      size={"small"}
      fullWidth
      variant={"standard"}
      placeholder={"Announcement"}
      onChange={(event) => {
        props.onChange?.(event.target.value || "")
      }}
    />
  )
}
