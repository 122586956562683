import { Button, Stack } from "@mui/material"
import type { FC } from "react"

type Props = {
  isThisWeek: boolean
  isNextWeek: boolean
  onSelectThisWeek(): void
  onSelectNextWeek(): void
}

export const NextWeekBox: FC<Props> = (props) => {
  return (
    <Stack direction={"row"} spacing={1} sx={{ height: "100%" }}>
      <Button
        variant={"text"}
        color={props.isThisWeek ? "primary" : "inherit"}
        onClick={props.onSelectThisWeek}
      >
        {"THIS WEEK"}
      </Button>
      <Button
        variant={"text"}
        color={props.isNextWeek ? "primary" : "inherit"}
        onClick={props.onSelectNextWeek}
      >
        {"NEXT WEEK"}
      </Button>
    </Stack>
  )
}
