import { Stack, TextField, Typography } from "@mui/material"
import type { FC } from "react"
import { useState, useEffect } from "react"
import { ResponsiveDrawer } from "app/components/ResponsiveDrawer"
import type { OnUpdateShift } from "interface/types/onUpdateShift"

type Props = {
  label: string | null
  dateText: string
  employeeName: string
  startTime: string
  endTime: string
  isOpenDrawer: boolean
  onClose: OnUpdateShift
}

export const ShiftTimeDrawer: FC<Props> = (props) => {
  const [startText, setStartText] = useState(() => {
    return props.startTime
  })

  const [endText, setEndText] = useState(() => {
    return props.endTime
  })

  const [label, setLabel] = useState(props.label ?? "")

  useEffect(() => {
    setStartText(props.startTime)
  }, [props.startTime])

  useEffect(() => {
    setEndText(props.endTime)
  }, [props.endTime])

  const onClose = () => {
    const [startTimeHour, startTimeMinute] = startText.split(":").map(Number)
    const [endTimeHour, endTimeMinute] = endText.split(":").map(Number)
    props.onClose({
      startTimeHour: startTimeHour,
      startTimeMinute: startTimeMinute,
      endTimeHour: endTimeHour,
      endTimeMinute: endTimeMinute,
      label: label || null,
    })
  }

  return (
    <ResponsiveDrawer open={props.isOpenDrawer} onClose={onClose}>
      <Stack spacing={2} sx={{ p: 2 }}>
        <Typography sx={{ fontWeight: "bold" }}>{props.dateText}</Typography>
        <Typography>{props.employeeName}</Typography>
        <TextField
          label="From"
          size={"small"}
          type="time"
          value={startText}
          onChange={(event) => {
            setStartText(event.target.value)
          }}
        />
        <TextField
          label="To"
          size={"small"}
          type="time"
          value={endText}
          onChange={(event) => {
            setEndText(event.target.value)
          }}
        />
        <TextField
          label="Label"
          size={"small"}
          value={label}
          onChange={(event) => {
            setLabel(event.target.value)
          }}
        />
      </Stack>
    </ResponsiveDrawer>
  )
}
