import { Stack, Box, Typography } from "@mui/material"
import type { FC } from "react"
import { ShiftTableCellEmpty } from "app/[business]/shifts/components/ShiftTableCellEmpty"
import { ShiftToggleButton } from "app/[business]/shifts/components/ShiftToggleButton"

export const ShiftTableCellCaptions: FC = () => {
  return (
    <Box sx={{ overflowX: "auto" }}>
      <Stack direction={"row"} spacing={2} sx={{ px: 2 }}>
        <Stack direction={"row"} spacing={1}>
          <ShiftTableCellEmpty isReadOnly={true} onAssign={() => {}} />
          <Typography variant="body2">Not a preferred shift.</Typography>
        </Stack>
        <Stack direction={"row"} spacing={1}>
          <ShiftToggleButton
            label={""}
            startTimeHour={10}
            startTimeMinute={10}
            endTimeHour={12}
            endTimeMinute={10}
            isReadOnly={false}
            isAssigned={false}
            isAssignable={true}
            isDuplicated={false}
            isDefaultStartTime={true}
            isDefaultEndTime={true}
            onClick={() => {}}
          />
          <Typography variant="body2">Preferred Shift</Typography>
        </Stack>
        <Stack direction={"row"} spacing={1}>
          <ShiftToggleButton
            label={""}
            startTimeHour={10}
            startTimeMinute={10}
            endTimeHour={12}
            endTimeMinute={10}
            isReadOnly={false}
            isAssigned={true}
            isAssignable={true}
            isDuplicated={false}
            isDefaultStartTime={true}
            isDefaultEndTime={true}
            onClick={() => {}}
          />
          <Typography variant="body2">
            Treat desired shift
            <br /> as attendance.
          </Typography>
        </Stack>
        <Stack direction={"row"} spacing={1}>
          <ShiftToggleButton
            label={""}
            startTimeHour={10}
            startTimeMinute={10}
            endTimeHour={12}
            endTimeMinute={10}
            isReadOnly={false}
            isAssigned={true}
            isAssignable={false}
            isDuplicated={false}
            isDefaultStartTime={true}
            isDefaultEndTime={true}
            onClick={() => {}}
          />
          <Typography variant="body2">
            Treat timeslots not requested for
            <br />
            attendance as attendance.
          </Typography>
        </Stack>
        <Stack direction={"row"} spacing={1}>
          <ShiftToggleButton
            label={""}
            startTimeHour={10}
            startTimeMinute={10}
            endTimeHour={12}
            endTimeMinute={10}
            isReadOnly={false}
            isAssigned={false}
            isAssignable={false}
            isDuplicated={true}
            isDefaultStartTime={true}
            isDefaultEndTime={true}
            onClick={() => {}}
          />
          <Typography variant="body2">Duplicates.</Typography>
        </Stack>
      </Stack>
    </Box>
  )
}
