import { useState } from "react"
import type { FC } from "react"
import { ScrollTable } from "app/[business]/shifts/components/ScrollTable"
import type {
  AssignableShiftFieldFragment,
  MemberFieldsFragment,
  ShiftRoleFieldsFragment,
  ShiftScheduleRequestFieldsFragment,
  TimeSlotFieldsFragment,
} from "interface/__generated__/react"
import type { OnCreateShift } from "interface/types/onCreateShift"
import type { OnDeleteShift } from "interface/types/onDeleteShift"

type Props = {
  dateTexts: string[]
  uiDateTexts: string[]
  shiftScheduleRequests: ShiftScheduleRequestFieldsFragment[]
  businessTimeSlots: TimeSlotFieldsFragment[]
  members: MemberFieldsFragment[]
  tableShiftRoles: ShiftRoleFieldsFragment[]
  assignableShifts: AssignableShiftFieldFragment[]
  isEditable: boolean
  onUpdateShift?: OnCreateShift
  onDeleteShift?: OnDeleteShift
}

/**
 * シフトのテーブル
 */
// export const ShiftTable = memo<Props>((props) => {
export const ShiftTable: FC<Props> = (props) => {
  const [noteWidths, setNoteWidths] = useState<number[]>([120])

  const toAssignableShifts = (
    memberId: string,
    dateText: string,
    shiftRole: ShiftRoleFieldsFragment,
  ) => {
    return props.assignableShifts.filter((shift) => {
      return (
        shift.memberId === memberId &&
        shift.dateText === dateText &&
        shift.shiftRoleId === shiftRole.id
      )
    })
  }

  const toMemberNote = (
    shiftScheduleRequests: ShiftScheduleRequestFieldsFragment[],
    memberId: String,
  ) => {
    const shiftScheduleRequest = shiftScheduleRequests.find((v) => {
      return v.memberId === memberId
    })
    if (shiftScheduleRequest == null) {
      return ""
    }
    return shiftScheduleRequest.note
  }

  const toFilteredMembers = (shiftRole: ShiftRoleFieldsFragment) => {
    return props.members.filter((member) => {
      if (member.isArchived) {
        return false
      }

      if (member.shiftRoles.map((e) => e.id).includes(shiftRole.id)) {
        return true
      }

      return false
    })
  }

  const totalRows: number = props.tableShiftRoles.reduce(
    (total, shiftRole) => total + toFilteredMembers(shiftRole).length,
    0,
  )

  const setWidth = (width: number) => {
    if ((noteWidths.length = totalRows)) {
      setNoteWidths((prev) => [...prev.slice(1, prev.length), width])
      return
    }
    setNoteWidths((prev) => [...prev, width])
  }

  return (
    <>
      {props.tableShiftRoles.map((shiftRole) => {
        return (
          <ScrollTable
            key={shiftRole.id}
            shiftRole={shiftRole}
            toFilteredMembers={toFilteredMembers}
            toAssignableShifts={toAssignableShifts}
            toMemberNote={toMemberNote}
            setWidth={setWidth}
            noteWidths={noteWidths}
            dateTexts={props.dateTexts}
            uiDateTexts={props.uiDateTexts}
            shiftScheduleRequests={props.shiftScheduleRequests}
            businessTimeSlots={props.businessTimeSlots}
            isEditable={props.isEditable}
            onUpdateShift={props.onUpdateShift}
            onDeleteShift={props.onDeleteShift}
          />
        )
      })}
    </>
  )
}
