import { Stack, Typography } from "@mui/material"
import { memo } from "react"

type Props = {
  isDefault?: boolean
  hour: number
  minute: number
}

export const ShiftTime = memo<Props>((props) => {
  // const timeEdited = false
  // props.defaultTime && !isSameTime(props.defaultTime, props.time)

  return (
    <Stack
      direction={"row"}
      justifyContent={"center"}
      justifyItems={"center"}
      spacing={0.25}
      sx={{ width: "100%" }}
    >
      <Typography
        textAlign={"right"}
        sx={{
          fontSize: 10,
          fontWeight: props.isDefault ? "normal" : "bold",
          width: "0.75rem",
          height: "0.75rem",
        }}
      >
        {props.hour}
      </Typography>
      <Typography
        textAlign={"left"}
        sx={{
          fontSize: 10,
          fontWeight: props.isDefault ? "normal" : "bold",
          opacity: props.minute === 0 ? 0.8 : 1,
          width: "0.75rem",
          height: "0.75rem",
        }}
      >
        {props.minute.toString().padStart(2, "0")}
      </Typography>
    </Stack>
  )
})
