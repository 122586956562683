import EditTwoToneIcon from "@mui/icons-material/EditTwoTone"
import { Box, ButtonBase } from "@mui/material"
import { grey } from "@mui/material/colors"
import { memo } from "react"

type Props = {
  isDisabled: boolean
  onClick(): void
}

export const ShiftTableCellEdit = memo<Props>((props) => {
  if (props.isDisabled) {
    return (
      <Box
        sx={{
          height: "2.5rem",
          width: "1rem",
          backgroundColor(theme) {
            return theme.palette.mode === "light" ? grey[50] : grey[700]
          },
        }}
      />
    )
  }

  return (
    <ButtonBase
      sx={{
        height: "2.5rem",
        width: "1rem",
        backgroundColor(theme) {
          return theme.palette.mode === "light" ? grey[50] : grey[700]
        },
      }}
      onClick={props.onClick}
    >
      <EditTwoToneIcon sx={{ fontSize: 10 }} />
    </ButtonBase>
  )
})
