import { Stack, Typography } from "@mui/material"
import type { FC, ReactNode } from "react"

type Props = {
  title: string
  children?: ReactNode
}

export const ViewHeader: FC<Props> = (props) => {
  return (
    <Stack
      direction={"row"}
      spacing={2}
      alignItems={"flex-end"}
      justifyContent={"space-between"}
    >
      <Typography fontWeight={"bold"} variant={"h6"}>
        {props.title}
      </Typography>
      {props.children && (
        <Stack direction={"row"} spacing={1}>
          {props.children}
        </Stack>
      )}
    </Stack>
  )
}
