import { Alert } from "@mui/material"
import type { FC } from "react"

type Props = {
  isConfirmed: boolean
}

export const ShiftTableAlert: FC<Props> = (props) => {
  if (props.isConfirmed) {
    return (
      <Alert severity={"success"}>{"This schedule has been submitted."}</Alert>
    )
  }

  return (
    <Alert severity={"warning"}>
      {"This schedule has not yet been submitted."}
    </Alert>
  )
}
