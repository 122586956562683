import { TableCell } from "@mui/material"
import type { FC, ReactNode } from "react"

type Props = {
  children: ReactNode
  zIndex?: number
}

export const StickyTableCell: FC<Props> = (props) => {
  const { zIndex = 1 } = props

  return (
    <TableCell
      component="th"
      scope="row"
      sx={{
        width: 120,
        minWidth: 120,
        maxWidth: 120,
        position: "sticky",
        left: 0,
        background(theme) {
          return theme.palette.mode === "light"
            ? theme.palette.common.white
            : theme.palette.grey[900]
        },
        zIndex,
        overflowX: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontWeight: "bold",
      }}
    >
      {props.children}
    </TableCell>
  )
}
