import { Stack } from "@mui/material"
import type { FC } from "react"
import { Fragment } from "react"
import { ShiftTableCellEmpty } from "app/[business]/shifts/components/ShiftTableCellEmpty"
import { ShiftTableCellSlot } from "app/[business]/shifts/components/ShiftTableCellSlot"
import type {
  AssignableShiftFieldFragment,
  TimeSlotFieldsFragment,
} from "interface/__generated__/react"
import type { OnCreateShift } from "interface/types/onCreateShift"
import type { OnDeleteShift } from "interface/types/onDeleteShift"
import type { OnUpdateShift } from "interface/types/onUpdateShift"

type Props = {
  dateText: string
  shiftRoleId: string
  businessTimeSlots: TimeSlotFieldsFragment[]
  memberId?: string
  memberName: string
  assignableShifts: AssignableShiftFieldFragment[]
  isEditable: boolean
  onUpdateShift?: OnCreateShift
  onDeleteShift?: OnDeleteShift
}

/**
 * テーブルのセル
 * ShiftTableDailyCells
 */
export const ShiftTableDailyCells: FC<Props> = (props) => {
  const shifts = props.businessTimeSlots.map((timeSlot) => {
    const value = props.assignableShifts.find((shift) => {
      return shift.timeSlotId === timeSlot.id
    })
    return value ?? null
  })

  /**
   * 従業員をアサイン条件を変更する
   * @param shift
   * @returns
   */
  const onUpdateFactory = (shift: AssignableShiftFieldFragment) => {
    const fn: OnUpdateShift = (form) => {
      props.onUpdateShift?.({
        note: form.label ?? "",
        year: shift.year,
        month: shift.month,
        day: shift.day,
        startTimeHour: form.startTimeHour,
        startTimeMinute: form.startTimeMinute,
        endTimeHour: form.endTimeHour,
        endTimeMinute: form.endTimeMinute,
        timeSlotId: shift.timeSlotId,
        shiftRoleId: shift.shiftRoleId,
        memberId: shift.memberId,
      })
    }
    return fn
  }

  /**
   * 従業員をアサインする
   * @param shift
   * @returns
   */
  const onAssignFactory = (shift: AssignableShiftFieldFragment) => {
    return () => {
      if (shift.shiftId !== null) {
        props.onDeleteShift?.({ shiftId: shift.shiftId })
      }
      if (shift.shiftId === null) {
        props.onUpdateShift?.({
          note: shift.note ?? "",
          year: shift.year,
          month: shift.month,
          day: shift.day,
          startTimeHour: shift.startTimeHour,
          startTimeMinute: shift.startTimeMinute,
          endTimeHour: shift.endTimeHour,
          endTimeMinute: shift.endTimeMinute,
          timeSlotId: shift.timeSlotId,
          shiftRoleId: shift.shiftRoleId,
          memberId: shift.memberId,
        })
      }
    }
  }

  const onCreateFactory = (timeSlot: TimeSlotFieldsFragment) => {
    return () => {
      const [year, month, day] = props.dateText.split("-").map(Number)
      if (props.memberId === undefined) return
      props.onUpdateShift?.({
        year: year,
        month: month,
        day: day,
        startTimeHour: timeSlot.startTimeHour,
        startTimeMinute: timeSlot.startTimeMinute,
        endTimeHour: timeSlot.endTimeHour,
        endTimeMinute: timeSlot.endTimeMinute,
        timeSlotId: timeSlot.id,
        shiftRoleId: props.shiftRoleId,
        memberId: props.memberId,
        note: "",
      })
    }
  }

  return (
    <Stack
      direction={"row"}
      spacing={0.5}
      alignItems="center"
      sx={{ height: "2.5rem", width: "fit-content" }}
    >
      {shifts.map((shift, index) => (
        <Fragment key={index}>
          {shift === null ? (
            <ShiftTableCellEmpty
              isReadOnly={!props.isEditable}
              onAssign={onCreateFactory(props.businessTimeSlots[index])}
            />
          ) : (
            <ShiftTableCellSlot
              label={shift.note}
              employeeName={props.memberName}
              dateText={props.dateText}
              startTime={shift.startTimeText}
              startTimeHour={shift.startTimeHour}
              startTimeMinute={shift.startTimeMinute}
              endTime={shift.endTimeText}
              endTimeHour={shift.endTimeHour}
              endTimeMinute={shift.endTimeMinute}
              isEditable={props.isEditable}
              isAssigned={shift.isAssigned}
              isAssignable={shift.isAssignable}
              isRequested={shift.isRequested}
              isDuplicated={shift.isDuplicated}
              isDefaultStartTime={shift.isDefaultStartTime}
              isDefaultEndTime={shift.isDefaultEndTime}
              onUpdate={onUpdateFactory(shift)}
              onAssign={onAssignFactory(shift)}
            />
          )}
        </Fragment>
      ))}
    </Stack>
  )
}
