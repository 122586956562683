import { ButtonBase } from "@mui/material"
import { grey } from "@mui/material/colors"
import { memo } from "react"

type Props = {
  isDisabled: boolean
  onClick(): void
}

export const ShiftEmptyButton = memo<Props>((props) => {
  return (
    <ButtonBase
      disabled={props.isDisabled}
      onClick={props.isDisabled ? undefined : props.onClick}
      sx={{
        py: 0,
        px: 0,
        width: "2.5rem",
        minWidth: "2.5rem",
        height: "2.5rem",
        position: "relative",
        borderRadius: "4px", // 角丸の枠線を追加

        backgroundColor(theme) {
          return theme.palette.mode === "light" ? grey[300] : grey[800]
        },
      }}
    />
  )
})
