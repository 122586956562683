import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import { useRef, useEffect } from "react"
import type { FC } from "react"
import { NoteTableCell } from "app/[business]/shifts/components/NoteTableCell"
import { ShiftTableDailyCells } from "app/[business]/shifts/components/ShiftTableDailyCells"
import { StickyTableCell } from "app/components/StickyTableCell"
import { VariableWidthTableCell } from "app/components/VariableWidthTableCell"
import type {
  AssignableShiftFieldFragment,
  MemberFieldsFragment,
  ShiftRoleFieldsFragment,
  ShiftScheduleRequestFieldsFragment,
  TimeSlotFieldsFragment,
} from "interface/__generated__/react"
import type { OnCreateShift } from "interface/types/onCreateShift"
import type { OnDeleteShift } from "interface/types/onDeleteShift"

type ScrollTableProps = {
  shiftRole: ShiftRoleFieldsFragment
  toFilteredMembers: (
    shiftRole: ShiftRoleFieldsFragment,
  ) => MemberFieldsFragment[]
  toAssignableShifts: (
    memberId: string,
    dateText: string,
    shiftRole: ShiftRoleFieldsFragment,
  ) => AssignableShiftFieldFragment[]
  toMemberNote: (
    shiftScheduleRequests: ShiftScheduleRequestFieldsFragment[],
    memberId: String,
  ) => string
  setWidth: (width: number) => void
  noteWidths: number[]
  dateTexts: string[]
  uiDateTexts: string[]
  shiftScheduleRequests: ShiftScheduleRequestFieldsFragment[]
  businessTimeSlots: TimeSlotFieldsFragment[]
  isEditable: boolean
  onUpdateShift?: OnCreateShift
  onDeleteShift?: OnDeleteShift
}

export const ScrollTable: FC<ScrollTableProps> = (props) => {
  const scrollRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const scrollElement = scrollRef.current

    const handleScroll = () => {
      if (scrollElement) {
        const isScrolledToTop = scrollElement.scrollTop === 0

        const isScrolledToBottom =
          scrollElement.scrollTop + scrollElement.clientHeight ===
          scrollElement.scrollHeight

        if (isScrolledToTop || isScrolledToBottom) {
          scrollRef.current?.style.setProperty("overscroll-behavior-y", "auto")
        } else {
          scrollRef.current?.style.setProperty("overscroll-behavior-y", "none")
        }
      }
    }

    if (scrollElement) {
      scrollElement.addEventListener("scroll", handleScroll)
    }

    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener("scroll", handleScroll)
      }
    }
  }, [])

  const filteredMembers = props.toFilteredMembers(props.shiftRole)

  const timeSlotCellWidth = `calc(${
    props.businessTimeSlots.length
  } * 2.5rem + ${props.businessTimeSlots.length - 1} * 4px + 2rem )`

  const noteMembersId = props.shiftScheduleRequests
    .filter((request) => request.note)
    .reduce((acc, cur) => {
      return [...acc, cur.memberId]
    }, [] as string[])

  const hasNote = noteMembersId.some((memberId) =>
    filteredMembers.some((member) => member.id === memberId),
  )

  return (
    <TableContainer
      ref={scrollRef}
      sx={{
        maxHeight: "calc(100vh - 56px)",
        overflow: "auto",
        overscrollBehaviorX: "none",
        overscrollBehaviorY: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <StickyTableCell zIndex={3}>
              <Typography fontWeight={"bold"} fontSize={20}>
                {props.shiftRole.name}
              </Typography>
            </StickyTableCell>
            {props.uiDateTexts.map((dateText) => (
              <TableCell
                key={dateText}
                sx={{
                  px: 0,
                  fontSize: 12,
                  textAlign: "center",
                  width: timeSlotCellWidth,
                }}
              >
                {dateText}
              </TableCell>
            ))}
            {filteredMembers.length > 0 && hasNote ? (
              <NoteTableCell>
                <Typography fontWeight={"bold"} fontSize={14}>
                  Note
                </Typography>
              </NoteTableCell>
            ) : (
              <VariableWidthTableCell width={Math.max(...props.noteWidths)}>
                <Typography fontWeight={"bold"} fontSize={14}>
                  Note
                </Typography>
              </VariableWidthTableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredMembers.map((member) => (
            <TableRow
              key={member.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <StickyTableCell>
                <Stack direction={"column"}>
                  <Typography
                    fontWeight={"bold"}
                    fontSize={14}
                    overflow={"hidden"}
                    textOverflow={"ellipsis"}
                    whiteSpace={"nowrap"}
                  >
                    {member.name}
                  </Typography>
                  <Typography
                    fontSize={12}
                    overflow={"hidden"}
                    textOverflow={"ellipsis"}
                    whiteSpace={"nowrap"}
                  >
                    {member.label}
                  </Typography>
                </Stack>
              </StickyTableCell>
              {props.dateTexts.map((dateText) => (
                <TableCell
                  key={dateText}
                  sx={{ px: 0, fontSize: 12, textAlign: "center" }}
                >
                  <Stack
                    direction={"column"}
                    alignItems={"center"}
                    sx={{ px: 2 }}
                  >
                    <ShiftTableDailyCells
                      dateText={dateText}
                      shiftRoleId={props.shiftRole.id}
                      memberName={member.name}
                      memberId={member.id}
                      businessTimeSlots={props.businessTimeSlots}
                      assignableShifts={props.toAssignableShifts(
                        member.id,
                        dateText,
                        props.shiftRole,
                      )}
                      isEditable={props.isEditable}
                      onUpdateShift={props.onUpdateShift}
                      onDeleteShift={props.onDeleteShift}
                    />
                  </Stack>
                </TableCell>
              ))}
              {filteredMembers.length > 0 && hasNote && (
                <NoteTableCell setWidth={props.setWidth}>
                  {props.toMemberNote(props.shiftScheduleRequests, member.id)}
                </NoteTableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
